<template>
  <section class="aishopNew_m">
    <div class="dialogcontainer">
      <div class="contactus">
        <el-dialog
          class="project_trash_dialog"
          :visible.sync="ContactUsVisible"
          width="288px"
          :close-on-click-modal="false"
          :show-close="false"
          style="margin-top: 15vh; padding: 0"
        >
          <div class="title">
            <span class="title_text">暂不支持手机端访问</span>
            <span class="title_text">请前往PC网页端进行注册体验</span>
          </div>
          <div class="dialog_button">
            <div class="close" @click="ContactUsVisible = false">我知道了</div>
          </div>
        </el-dialog>
      </div>
    </div>

    <!-- <div class="active" v-show="acticityShow">
      <img
        src="../../../assets/zh/images-mobile/shouye_huodong@2x.png"
        alt=""
        @click="RouteActicity()"
      />
      <span
        style="color: white"
        class="iconfont iconToast_closed close"
        @click="acticityShow = false"
      ></span>
    </div> -->

    <div class="banner">
      <div class="button">
        <el-button
          class="unchecked"
          style="width: 68px;"
          @click="RoutePush('/CooVallyHome')"
          >{{ $t("PC.Product.CooVallyButton.deaa52") }}</el-button
        >
        <el-button class="unchecked" style="width: 68px;" @click="RoutePush('/CooVally')">{{
          $t("PC.Product.CooVallyButton.2446d2")
        }}</el-button>
        <el-button class="checked">{{
          $t("PC.Product.CooVallyButton.fad28a")
        }}</el-button>
      </div>
    </div>

    <div class="main">
      <div class="banner">
        <div class="title" v-show="$t('locale') == 'zh'">
          <span>{{ $t("PC.Product.AISHOPNew.1d4fa3") }}</span>
          <span class="strong">
            <span class="color">{{ $t("PC.Product.AISHOPNew.70e312") }}</span
            >{{ $t("PC.Product.AISHOPNew.kocz12") }}
          </span>
        </div>

        <div class="title" v-show="$t('locale') == 'en'">
          <span>{{ $t("PC.Product.AISHOPNew.1d4fa3") }}</span>

          <span class="strong_en">
            <span class="color">{{ $t("PC.Product.AISHOPNew.70e312") }}</span
            >{{ $t("PC.Product.AISHOPNew.kocz12") }}</span
          >
        </div>

        <div class="text">
          <p>
            {{ $t("PC.Product.AISHOPNew.4d7bd7") }}
          </p>
        </div>
        <div class="contactus">
          <el-button
            class="contactusbutton"
            @click="GoCooVally()"
            v-show="$t('locale') == 'zh'"
          >
            {{ $t("PC.Product.CooVallyButton.ed4a49") }}</el-button
          >

          <el-button
            class="contactusbutton_en"
            @click="GoCooVally()"
            v-show="$t('locale') == 'en'"
          >
            {{ $t("PC.Product.CooVallyButton.ed4a49") }}</el-button
          >
        </div>
      </div>
      <div class="banner_img">
        <img
          src="../../../assets/images/CooVally/chahua@2x.png"
          style="width: 100%; height: 100%"
          alt=""
        />
      </div>
    </div>

    <div class="scene" v-show="$t('locale') == 'zh'">
      <!-- <div class="topSticky">
          <div class="topContent">
            <el-input
              v-model="inpVal"
              placeholder="请输入关键词"
              @keyup.enter.native="getscenelist(inpVal)"
            ></el-input>
          </div>
        </div> -->
      <div class="mallMain">
        <div class="sceneListBox">
          <div
            class="caseBlock"
            v-for="item in sceneList"
            :key="item.id"
            @click="GoDetail(item)"
          >
            <img :src="imgServeUrl + item.url" alt="" />

            <div class="caseContent">
              <div class="title">
                <p>{{ item.scene_name }}</p>
              </div>
              <div class="text">
                <div class="miaoshu">
                  {{ item.brief_introduction }}
                </div>
              </div>

              <div class="tags">
                <span
                  class="tag"
                  v-for="(item, index) in item.labels.split(',')"
                  :key="index"
                  >{{ item }}</span
                >
                <span class="views">
                  <span class="iconfont icon-view"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="scene" v-show="$t('locale') == 'en'">
      <!-- <div class="topSticky">
          <div class="topContent">
            <el-input
              v-model="inpVal"
              placeholder="请输入关键词"
              @keyup.enter.native="getscenelist(inpVal)"
            ></el-input>
          </div>
        </div> -->
      <div class="mallMain">
        <div class="sceneListBox">
          <div class="caseBlock" v-for="item in sceneList" :key="item.id">
            <img :src="imgServeUrl + item.url" alt="" />

            <div class="caseContent">
              <div class="title">
                <p>{{ item.scene_name_en }}</p>
              </div>
              <div class="text">
                <div class="miaoshu">
                  {{ item.brief_introduction_en }}
                </div>
              </div>

              <div class="tags">
                <span
                  class="tag"
                  v-for="(item, index) in item.labels_en.split(',')"
                  :key="index"
                  >{{ item }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterB></FooterB>
  </section>
</template>

<script>
import FooterB from "../components/FooterB";
export default {
  components: {
    FooterB,
  },
  data() {
    return {
      acticityShow: true,
      ContactUsVisible: false,
      sceneCategoryId: "",
      sceneList: undefined,
      imgServeUrl: Global.IMG_SERVE,
    };
  },

  created() {
    this.getscenelist("");
  },

  methods: {
    RoutePush(url) {
      this.$router.push({
        path: url,
      });
    },
    RouteActicity() {
      let routeData = this.$router.resolve({ path: "/Acticity" });
      window.open(routeData.href, "_blank");
    },

    GoCooVally() {
      this.ContactUsVisible = true;
    },

    getscenelist() {
      this.axios
        .get(Global.COOVALLY_URL + Global.GET_SCENE_LIST + "?page_size=100")
        .then((res) => {
          // console.log(res);
          if (res.status == 200 || res.status == 201) {
            this.sceneList = res.data.results;
          } else {
            this.$message({
              message: "请求出错",
              type: "error",
            });
          }
        })
        .catch((error) => {
          return error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.aishopNew_m {
  margin-top: 48px;
  word-break: break-word !important;
  width: 100%;

  .dialogcontainer {
    .contactus {
      /deep/ .el-dialog__header {
        padding: 0;
      }

      /deep/ .el-dialog__body {
        padding: 0;
        margin-top: -10px;
      }

      .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
        text-align: center;
        margin-top: 25px;
        padding-bottom: 10px;
      }
      /deep/ .dialog_button {
        padding-top: 14px;
        .close {
          width: 100%;
          height: 50px;
          padding-top: 13px;
          text-align: center;
          border-top: 1px solid #eaeaf0;
          // background: red;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2b2c3d;
          line-height: 22px;
        }
        .close:hover {
          color: #2b2c3dcc;
        }
      }
    }
  }

  .active {
    // display: flex;
    position: relative;
    z-index: 2;
    img {
      width: 100%;
      height: 100%;
    }

    .close {
      z-index: 99;
      position: absolute;
      right: 1vh;
      top: 8%;
      // background: red;
      // widows: 20px;
      height: 20px;
    }
  }

  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    .button {
      display: flex;
      justify-content: space-between;
      width: 260px;
      padding-top: 12px;

      .checked {
        width: 83px;
        height: 30px;
        background-color: #6d71f9;
        color: #fafcff;
        border-radius: 4px;
        padding: 0;
        font-weight: 600;
        border: none;
        line-height: 29px;
        font-size: 14px;
        z-index: 1;
      }
      .unchecked {
        z-index: 1;

        width: 83px;
        height: 30px;
        background-color: #e8e7f7;
        color: #6d71f9;
        border-radius: 4px;
        padding: 0;
        font-weight: 600;
        border: none;
        line-height: 29px;
        font-size: 14px;
      }
    }
  }

  .main {
    // background-color: green;
    // width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // margin-top: 3%;

    .banner {
      // z-index:;
      width: 100%;
      height: 223px;
      display: flex;
      background-image: url("../../../assets/zh/images-mobile/bg2@3x.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: -60px;
      position: relative;

      .strong {
        margin-left: 12px;
        width: 64px;
        height: 16px;
        box-shadow: 0px 4px 0px 0px rgba(#4640de, 0.2);
      }
      .strong_en {
        margin-left: 12px;
        width: 174px;
        height: 16px;
        box-shadow: 0px 4px 0px 0px rgba(#4640de, 0.2);
      }

      .title {
        margin-top: 77px;
        width: 350px;
        text-align: center;
        // height: 62px;
      }
      span {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2d3242;
        line-height: 22px;
      }
      .color {
        color: #6d71f9;
      }

      .text {
        width: 335px;
        // height: 40px;
        text-align: center;
        margin-top: 6px;
        // position: absolute;
        p:nth-of-type(1) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #636c78;
          line-height: 20px;
        }
      }
      .contactus {
        // position: absolute;
        margin-top: 12px;
        .contactusbutton {
          width: 80px;
          height: 29px;
          background: #6d71f9;
          border-radius: 4px;
          padding: 0;
          font-size: 12px;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #ffffff;
          // line-height: 17px;
          font-weight: 600;
          border: none;
          line-height: 29px;
        }

        .contactusbutton_en {
          width: 108px;
          height: 29px;
          background: #6d71f9;
          border-radius: 4px;
          padding: 0;
          font-size: 12px;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #ffffff;
          // line-height: 17px;
          font-weight: 600;
          border: none;
          line-height: 29px;
        }
      }
    }

    .banner_img {
      display: flex;
      margin: 0 auto;
      margin-top: -10px;
      margin-bottom: 10px;
      width: 370px;
      height: 310px;
    }
  }
  .scene {
    width: 100%;
    display: flex;
    justify-content: center;
    .mallMain {
      width: 360px;
      padding-top: -30px;
      padding-bottom: 30px;
      .sceneListBox {
        flex-wrap: wrap;
        display: flex;
        justify-content: start;
        align-items: flex-start;
        .caseBlock {
          cursor: pointer;
          width: 352px;
          margin: 12px 4px;
          background: #f9f9fc;
          border-radius: 4px;
          border: 1px solid #eeeef6;
          img {
            width: 350px;
            height: 280px;
            border-radius: 4px 4px 0px 0px;
          }

          div.caseContent {
            padding-left: 14px;
            padding-bottom: 20px;
            box-sizing: border-box;
            .title {
              margin-top: 18px;
              width: 340px;
              height: 25px;

              p:nth-of-type(1) {
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #2d3242;
                line-height: 25px;
              }
            }

            .text {
              // position: relative;
              // top: 33px;
              margin-top: 8px;
              width: 330px;
              .miaoshu {
                width: 330px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #636c78;
                line-height: 21px;
              }
            }
            .tags {
              font-size: 14px;
              color: #429fff;
              // position: relative;
              // top: 63px;
              margin-top: 12px;
              .tag {
                display: inline-block;
                margin-right: 0px;
                line-height: initial;
                padding: 4px 3px;
                color: #429fff;
                background-color: #ecf5ff;
                border-radius: 2px;
                border: 1px solid #d9ebff;
                transform: scale(0.85);
                transform-origin: left top;
              }
              .views {
                position: absolute;
                right: 0;
                color: #666666;
                line-height: 17px;
                span {
                  height: 100%;
                  font-size: 12px;
                  display: inline-block;
                  vertical-align: top;
                }

                .viewsNum {
                  max-width: 44px;
                  overflow: hidden;
                  white-space: nowrap;
                  word-break: break-all;
                }

                .icon-view {
                  font-size: 17px;
                  margin-right: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
